import SocialMediaIcons from "../components/SocialMediaIcons";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Landing = ({setSelectedPage}) => {
    const aboveLarge = useMediaQuery("(min-width: 1060px)");

    return ( 
        <section id="home" className="flex flex-col-reverse sm:flex-row justify-between items-center gap-16 md:py-[5%] pt-5">

            {/* IMAGE SECTION */}
          <div className="md:order-2 flex justify-center basis-3/5 z-10 mt-16 md:mt-32">
            {aboveLarge ? (
                <div className="">
                    <img src="assets/profile-imagee.jpg" alt="profile" className=" filter saturate-200 transition duration-500 z-10 rounded-t-full w-[400px] h-[600px] object-cover object-top"/>
                </div>
            ) : (
                <img src="assets/profile-imagee.jpg" alt="profile" className="w-full  rounded-t-full h-[600px] object-cover object-top filter saturate-200"/>
            )}
            </div> 
            

            {/* MAIN SECTION */}
            <div className="z-30 basis-2/5 pt-32 md:mt-28">
                {/* HEADINGS */}
                <motion.div initial="hidden" 
                whileInView="visible" 
                viewport={{ once: true, amount: 0.5}}    
                transition={{ duration: 0.5}} variants={{
                    hidden: { opacity: 0, x: -50},
                    visible: { opacity: 1, x: 0}
                }}>
                    <p className="text-6xl font-playfair z-10 text-center md:text-start flex flex-wrap justify-center md:justify-normal md:flex-nowrap md:gap-3">
                        Omotosho {""}
                        <span className=" relative text-deep-blue font-semibold z-20 before:content-brush before:absolute before:-left-[25px] before:-top-[80px] before:z-[-1] top-8 md:top-0">
                          Toheeb  
                        </span>
                    </p>
                    <p className=" sm:mt-10 mb-7 text-sm text-center md:text-start mt-20">
                       Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat laudantium ullam neque. Sunt, voluptatem reiciendis? 
                    </p>
                </motion.div>

                {/* CALL TO ACTIONS */}
              <motion.div
                 className="flex mt-5 justify-center md:justify-start"
                 initial="hidden" 
                 whileInView="visible" viewport={{ once: true, amount: 0.5}}
                 transition={{ delay: 0.4, duration: 0.5}}
                 variants={{
                 hidden: { opacity: 0, x: -50},
                 visible: { opacity: 1, x: 0}
                 }}>

                    <AnchorLink className=" bg-gradient-rainblue text-deep-blue rounded-sm py-3 px-7 font-semibold hover:bg-blue hover:text-white transition duration-500" onClick={() => setSelectedPage('contact')} href="#contact">
                        Contact Me
                    </AnchorLink>
                    <AnchorLink className=" rounded-r-sm bg-gradient-rainblue py-0.5 pr-0.5" onClick={() => setSelectedPage('contact')} href="#contact">

                        <div className=" bg-deep-blue hover:text-red transition duration-500 w-full h-full flex items-center justify-center font-playfair px-10">
                            Let's talk
                        </div>
                    </AnchorLink>
              </motion.div>

                 <motion.div
                    className="flex mt-5 justify-center md:justify-start"
                    initial="hidden" 
                    whileInView="visible" viewport={{ once: true, amount: 0.5}} transition={{ delay: 0.6, duration: 0.5}}
                    variants={{
                    hidden: { opacity: 0, x: -50},
                    visible: { opacity: 1, x: 0}
                    }}
                 >
                    <SocialMediaIcons />
                 </motion.div>
            </div>
        </section>
    );
}
 
export default Landing;